import React from 'react';
import getIconForText from '../functions/mapTextToIcon';

class Card extends React.Component {
  
  render() {
    return (
      <div className="col-lg-4">
        <div className="card mx-3 my-5">
          <div className="card-body" >
            <h2 className="card-title">{this.props.cardTitle}</h2>
            <p className="card-text">{this.props.cardSubtitle}</p>
            <a href={this.props.link} className="card-link stretched-link" target="_blank" rel="noopener noreferrer">
              Check Me Out!&nbsp;
            </a>
            {getIconForText(this.props.icon)}
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
