import React from 'react';

import {
  FaGithub as Github,
  FaLink as Link
} from 'react-icons/fa';

import {
  GiCook as Cook
} from 'react-icons/gi'

import {
  BiRun as Runner
} from 'react-icons/bi'


const iconMap = new Map([
  ['link', Link],
  ['github', Github],
  ['cook', Cook],
  ['runner', Runner]
]);

const createIcon = (iconName) => {
  return React.createElement(iconMap.get(iconName), {className: 'float-right'})
}

export default createIcon;